import { initializeApp } from 'https://www.gstatic.com/firebasejs/9.6.1/firebase-app.js'
import { 
  getAuth, 
  inMemoryPersistence,
  signInWithEmailAndPassword,
  signOut 
} from 'https://www.gstatic.com/firebasejs/9.6.1/firebase-auth.js'

const firebaseConfig = { 
  apiKey: "AIzaSyCTISd1-b4tqZ1gw8m8JxEyISDZ6oMl_3Q",
  authDomain: "gcp-project-request.firebaseapp.com",
  projectId: "gcp-project-request",
  storageBucket: "gcp-project-request.appspot.com",
  messagingSenderId: "330168392346",
  appId: "1:330168392346:web:eea72576cabec61577dd23"
}

initializeApp(firebaseConfig)
getAuth().setPersistence(inMemoryPersistence)


window.addEventListener("DOMContentLoaded", () => {
  document.getElementById("signin-form").addEventListener("submit", async (event) => {
    event.preventDefault()

    const emailElement = event.target.email
    const passwordElement = event.target.password
    const alertDangerElement = document.querySelector('div.alert.alert-danger')
    const loading = document.querySelector('.loading')

    // Hide alert
    hideAlert(alertDangerElement)

    const isEmailValid = checkEmail(emailElement)
    const isPasswordValid = checkPassword(passwordElement)
    const isValid = isEmailValid && isPasswordValid

    if (!isValid) return 

    const email = emailElement.value.trim()
    const password = passwordElement.value.trim()

    try {
      loading.style.display = 'flex'

      const result = await signInWithEmailAndPassword(getAuth(), email, password)
      const idToken = await result.user.getIdToken()

      await fetch("/auth/sessionLogin", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CSRF-Token": Cookies.get("XSRF-TOKEN"),
        },
        body: JSON.stringify({ idToken })
      })

      await signOut(getAuth())
      window.location.assign("/")
    } catch(exception) {
      switch (exception.code) { 
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          showAlert(alertDangerElement, 'ユーザー名かパスワードが間違っています。もう1度ご確認ください。')
          break
        case 'auth/user-disabled':
          showAlert(alertDangerElement, 'ユーザーアカウントが管理者によって無効にされています。')
          break
        default:
          showAlert(alertDangerElement, 'エラーが発生しました。')
          break
      }

      loading.style.display = 'none'
    }
  })
})  